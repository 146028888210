import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import PostCard from "./PostCard";
import { Heading, BlueText } from "../styles/Global.styled";
import { SkillsCardContainer } from "../styles/Cards.styled";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(_createdAt desc){
          title,
          description,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          slug,
          publishedAt
        }`
      )
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching posts:", error.message));
  }, []);

  return (
    <SkillsCardContainer>
      <Heading as="h2" size="h2" top="0.5rem">
        My <BlueText>Diary</BlueText>
      </Heading>
      {posts.map((post) => (
        <PostCard
          key={post.slug.current}
          title={post.title}
          description={post.description}
          image={post.mainImage?.asset?.url}
          timestamp={post.publishedAt}
          slug={post.slug.current}
          date={new Date(post.publishedAt).toLocaleDateString()}
        />
      ))}
    </SkillsCardContainer>
  );
};

export default Posts;
