import React from "react";
import { motion } from "framer-motion";
import {resumeLink} from "../utils/Data"
import {
  PaddingContainer,
  Heading,
  ParaText,
  FlexContainer,
  BlueText,
  IconContainer,
  Button,
} from "../styles/Global.styled";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { SiGmail, SiWhatsapp, SiYoutube, SiBluesky } from "react-icons/si";
import {
  ShowcaseParticleContainer,
  ShowcaseImageCard,
  Image,
  Particle,
  StyledLink,
  ResumeButoon,
} from "../styles/Showcase.styled";
import showcaseImage from "../assets/profile2.jpg"
import BackgroundImage from "../assets/particle.png";
import { fadeInLeftVariant, fadeInRightVariant } from "../utils/Variants";
import { TypeAnimation } from "react-type-animation";

const Showcase = () => {
  return (
    <PaddingContainer
      id="Home"
      left="3%"
      right="10%"
      top="10%"
      bottom="10%"
      responsiveTop="8rem"
      responsiveLeft="1rem"
      responsiveRight="1rem"
    >
      <FlexContainer align="center" fullWidthChild>
        {/*left content */}
        <motion.div
          style={{ textAlign: "left" }}
          variants={fadeInLeftVariant}
          initial="hidden"
          whileInView="visible"
        >
          <Heading as="h4" size="h4" style={{ letterSpacing: "2px" }}>
            Hello!
          </Heading>
          <Heading as="h2" size="h2" top="0.5rem" bottom="1rem">
            I'm <BlueText style={{ 
              background: "linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}>Kihembo Wilbert</BlueText>
          </Heading>
          <Heading as="h3" size="h3">
            I'm a{" "}
            <BlueText>
              <TypeAnimation
                sequence={[
                  "Software Engineer",
                  1000,
                  "Disability Rights Advocate",
                  1000,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </BlueText>
          </Heading>
          <ParaText as="p" top="2rem" bottom="2rem">
          I'm a dedicated Software Engineer living with cerebral palsy. I earned my diploma in Computer Science from International University of East Africa and am currently pursuing a Bachelor's degree in Information Technology at Cavendish University. I am driven by goals and envision a future where technology transforms the lives of people with disabilities. 
          </ParaText>
         

          {/* social icons */}
          <FlexContainer 
            gap="25px" 
            responsiveFlex 
            style={{
              background: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              padding: "1.8rem",
              borderRadius: "20px",
              marginBottom: "2.5rem",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              "@media (max-width: 768px)": {
                gap: "15px",
                padding: "1.2rem",
                marginBottom: "2rem"
              }
            }}
          >
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink
                target="blank"
                href="https://www.linkedin.com/in/kihembowilb/"
              >
                <BsLinkedin />
              </StyledLink>
            </IconContainer>
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink target="blank" href="https://bsky.app/profile/kihembo.bsky.social">
                <SiBluesky />
              </StyledLink>
            </IconContainer>
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink target="blank" href="https://www.facebook.com/kihembo.wilbert">
                <BsFacebook  />
              </StyledLink>
            </IconContainer>
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink target="blank" href="https://www.youtube.com/@kihembowilbert5433">
                <SiYoutube />
              </StyledLink>
            </IconContainer>
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink target="blank" href="mailto:kihembowilb@gmail.com">
                <SiGmail />
              </StyledLink>
            </IconContainer>
            <IconContainer 
              color="white" 
              size="1.7rem"
              style={{ 
                transition: "transform 0.3s ease",
                "@media (max-width: 768px)": {
                  fontSize: "1.4rem"
                }
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-5px)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
            >
              <StyledLink target="blank" href="https://wa.me/256789618464">
                <SiWhatsapp />
              </StyledLink>
            </IconContainer>
          </FlexContainer>
          {/* <ResumeButoon
            href={resumeLink}
            target="blank"
          >
            Resume
          </ResumeButoon> */}
        </motion.div>

        {/* right content */}
        <FlexContainer
          as={motion.div}
          justify="flex-end"
          align="center"
          variants={fadeInRightVariant}
          initial="hidden"
          whileInView="visible"
        >
          <ShowcaseParticleContainer>
            <ShowcaseImageCard
              style={{
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                ":hover": {
                  transform: "scale(1.02)",
                  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)"
                }
              }}
            >
              <Image src={showcaseImage} alt="profile" style={{ borderRadius: "25px" }} />
            </ShowcaseImageCard>
            <Particle
              as={motion.img}
              animate={{
                x: [0, 100, 0],
                rotate: 360,
                scale: [1, 0.9, 1],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              src={BackgroundImage}
              alt="particle"
              top="-80px"
              left="40px"
              rotate="40deg"
              style={{ opacity: 0.7 }}
            />
            <Particle
              as={motion.img}
              animate={{
                y: [0, 100, 0],
                rotate: -360,
                scale: [1, 0.9, 1],
              }}
              transition={{
                duration: 30,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              src={BackgroundImage}
              alt="particle"
              top="100px"
              right="-80px"
              rotate="10deg"
              style={{ opacity: 0.7 }}
            />
            <Particle
              as={motion.img}
              animate={{
                y: [0, -100, 0],
                rotate: -360,
                scale: [1, 0.9, 1],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              src={BackgroundImage}
              alt="particle"
              bottom="10px"
              left="-90px"
              rotate="50deg"
              style={{ opacity: 0.7 }}
            />
          </ShowcaseParticleContainer>
        </FlexContainer>
      </FlexContainer>
    </PaddingContainer>
  );
};

export default Showcase;
