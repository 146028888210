import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { SkillsCard, PostImage, PostInfo, Timestamp } from "../styles/Cards.styled";
import { Heading, ParaText } from '../styles/Global.styled';
import styled from 'styled-components';

// Update SkillsCard to be smaller and have a dark theme
const DarkSkillsCard = styled(SkillsCard)`
  background-color: #2c2c2c; // Dark background color
  color: white; // White text color
  max-width: 300px; // Make the card smaller
  border-radius: 15px; // Round corners
  overflow: hidden; // Ensure content doesn't overflow
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Add some shadow for styling
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05); // Scale up the card on hover
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none; 
  color: inherit; 
`;

const StyledPostImage = styled(PostImage)`
  width: 100%; // Ensure the image fits the card width
  height: 150px; // Set a fixed height for the image
  object-fit: cover; // Cover the image within the set dimensions
`;

const StyledPostInfo = styled(PostInfo)`
  padding: 10px; // Add padding to the post info
`;

const StyledTimestamp = styled(Timestamp)`
  font-size: 0.8rem; // Make the timestamp font smaller
  color: #ccc; // Light gray color for the timestamp
`;

const PostCard = ({ title, description, image, timestamp, slug }) => {
  return (
    <DarkSkillsCard>
      <StyledLink to={`/diary/${slug}`}>
        <StyledPostImage src={image} alt={title} />
        <StyledPostInfo>
          <Heading as="h4" size="h4">
            {title}
          </Heading>
          <ParaText>{description}</ParaText>
          {/* <StyledTimestamp>{new Date(timestamp).toLocaleDateString()}</StyledTimestamp> */}
        </StyledPostInfo>
      </StyledLink>
    </DarkSkillsCard>
  );
};

export default PostCard;
